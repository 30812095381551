@use '@angular/material' as mat;
@import "src/assets/themes/fmtheme.scss";


@function mat-typography-config($font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $display-4: mat-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
    $display-3: mat-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
    $display-2: mat-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
    $display-1: mat-typography-level(34px, 40px, 400),
    $headline: mat-typography-level(24px, 32px, 400),
    $title: mat-typography-level(20px, 32px, 500),
    $subheading-2: mat-typography-level(16px, 28px, 400),
    $subheading-1: mat-typography-level(15px, 24px, 400),
    $body-2: mat-typography-level(14px, 24px, 500),
    $body-1: mat-typography-level(14px, 20px, 400),
    $caption: mat-typography-level(12px, 20px, 400),
    $button: mat-typography-level(14px, 14px, 500),
    $input: mat-typography-level(inherit, 1.125, 400)) {
    // ..rest of function goes here
}

.auth-container {
    padding            : 30px 0;
    margin             : 0 auto;
    background         : url(/assets/images/bg.jpg) no-repeat;
    background-size    : cover;
    min-height         : 100vh;
    display            : flex;
    align-items        : center;
    background-position: center;
}

.auth-container .log-in-box {
    margin   : 0 auto;
    max-width: 450px;
}

.card {
    border-radius     : 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow        : 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border            : none;
    margin-bottom     : 30px;
    padding           : 5px;
}

.card-block {
    transition: 0.3s;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding   : 1.25rem;
}

.navbar {
    background-color: #fff;
}

.f-b-5 {
    font-size  : 14px;
    color      : #464646;
    font-weight: 500;
}

.f-b-5:hover,
.f-b-5:active,
.f-b-5:focus {
    text-decoration: none;
    color          : #00bdb3;
}

.button-red {
    background-color: #ff4d5f !important;
    width           : 100%;
    border-color    : #ff4d5f !important;
    outline         : none !important;
    padding         : 10px 16px !important;
    font-size       : 15px;
    line-height     : 23px !important;
}

.button-red:hover,
.button-red:focus,
.button-red:active {
    background-color: #ff4d5f !important;
    width           : 100%;
    border-color    : #ff4d5f !important;
    outline         : none !important;
}

// for margins

.m-b-1 {
    margin-bottom: 1.25em;
}

.m-b-0 {
    margin-bottom: 0;
}

.m-0 {
    margin: 0;
}

.m-t-0 {
    margin-top: 0;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-b-0 {
    margin-bottom: 0;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-l-0 {
    margin-left: 0;
}

.m-l-10 {
    margin-left: 10px;
}

.m-r-0 {
    margin-right: 0;
}

.m-r-5 {
    margin-right: 5px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-15 {
    margin-right: 15px;
}

.m-r-20 {
    margin-right: 20px;
}

.form-logo {
    width: 150px;
}

.custom-container {
    display       : flex;
    flex-direction: column;
}

//  for loader in login button
.lds-dual-ring {
    display: inline-block;
    width  : 100%;
    height : 20px;
}

.lds-dual-ring:after {
    content      : " ";
    display      : block;
    width        : 30px;
    height       : 30px;
    border-radius: 50%;
    border       : 6px solid #fff;
    border-color : #fff transparent #fff transparent;
    animation    : lds-dual-ring 1.2s linear infinite;
    margin       : 0 auto;
    margin-top   : -6px;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.lds-ripple div {
    position     : absolute;
    border       : 4px solid #00bdb3;
    opacity      : 1;
    border-radius: 50%;
    animation    : lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top    : 36px;
        left   : 36px;
        width  : 0;
        height : 0;
        opacity: 1;
    }

    100% {
        top    : 0px;
        left   : 0px;
        width  : 72px;
        height : 72px;
        opacity: 0;
    }
}

// checked end

button:focus,
button:active,
button:hover {
    outline: none !important;
}

.content {
    position: relative;
}

.overlay {
    position  : absolute;
    left      : 0;
    top       : 0;
    right     : 0;
    bottom    : 0;
    z-index   : 99999;
    background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
}

.overlay-content .lds-ripple {
    display : inline-block;
    position: relative;
    width   : 80px;
    height  : 80px;
    position: fixed;
    z-index : 999;
    height  : 2em;
    width   : 2em;
    overflow: show;
    margin  : auto;
    top     : 0;
    left    : 0;
    bottom  : 0;
    right   : 0;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow        : inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius     : 10px;
    background-color  : #f5f5f5;
}

::-webkit-scrollbar {
    width           : 6px;
    background-color: #f5f5f5;
    height          : 8px;
}

::-webkit-scrollbar-thumb {
    border-radius     : 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow        : inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color  : #aaa;
}

.mb-md-0 {
    margin-bottom: 0 !important;
}

input:-webkit-autofill {
    -webkit-box-shadow     : 0 0 0 50px white inset;
    box-shadow             : 0 0 0 50px white inset;
    /* Change the color to your own background color */
    -webkit-text-fill-color: #464646;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow     : 0 0 0 50px white inset;
    box-shadow             : 0 0 0 50px white inset;
    /*your box-shadow*/
    -webkit-text-fill-color: #464646;
}

/* fuse end  */
.is_active {
    background-color : #0b0d0d26 !important;
    color            : #464646 !important;
    height           : 40px;
    padding          : 0 12px 0 24px;
    border-radius    : 0 20px 20px 0;
    margin-right     : 16px;
    display          : flex;
    -webkit-box-align: center;
    align-items      : center;
}

.mat-sidenav .mat-expansion-panel-content {
    background-color: #ae9b9b1f !important;
}

.mat-expansion-panel-content {
    background-color: #fff !important;
}

.mat-radio-label-content,
.mat-expansion-panel {
    color: #464646;
}

.mat-content i {
    font-size   : 16px;
    padding     : 4px;
    margin-right: 10px;
    width       : 23px;
    display     : inline-block;
    height      : 26px;
    text-align  : center;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: #dadada;
}

.rember,
.forgot-phone {
    display: inline-block;
    width  : 50%;
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: none !important;
}

.mCustomScrollBox {
    position : relative;
    overflow : hidden;
    height   : 100%;
    max-width: 100%;
    outline  : none;
    direction: ltr;
}

.mat-expansion-panel-header {
    height: 48px !important;
}

.mat-sidenav .mat-drawer-container,
.mat-sidenav .mat-expansion-panel {
    background-color: #fff;
}

.mat-sidenav .mat-expansion-panel-header-title {
    color: #464646;
}

.mat-sidenav .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]),
.mat-sidenav .mat-expanded .mat-expansion-panel-header,
.mat-expansion-panel-header.mat-expanded:focus,
.mat-expansion-panel-header.mat-expanded:hover {
    background   : #fff !important;
    color        : #00bdb3;
    border-radius: 5px;
}

.mat-sidenav .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover .mat-expansion-panel-header-title,
.mat-sidenav .mat-expanded .mat-expansion-panel-header-title,
.mat-sidenav .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover .mat-expansion-indicator::after,
.mat-sidenav .mat-expanded .mat-expansion-panel-header-title,
.mat-sidenav .mat-expanded .mat-expansion-indicator::after {
    color: #00bdb3;
}

.mat-expansion-indicator::after {
    color: #464646;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
}

/* feather icons */
@font-face {
    font-family: feather;
    src        : url(/assets/fonts/feather.eot);
    src        : url(/assets/fonts/feathers.eot) format("embedded-opentype"), url(/assets/fonts/feather.woff) format("woff"),
        url(/assets/fonts/feather.ttf) format("truetype"), url(/assets/fonts/feather.svg) format("svg");
}

.feather {
    font-family            : feather !important;
    speak-as               : none;
    font-style             : normal;
    font-weight            : 400;
    font-variant           : normal;
    text-transform         : none;
    line-height            : 1;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-alert-octagon:before {
    content: "\e81b";
}

.icon-alert-circle:before {
    content: "\e81c";
}

.icon-activity:before {
    content: "\e81d";
}

.icon-alert-triangle:before {
    content: "\e81e";
}

.icon-align-center:before {
    content: "\e81f";
}

.icon-airplay:before {
    content: "\e820";
}

.icon-align-justify:before {
    content: "\e821";
}

.icon-align-left:before {
    content: "\e822";
}

.icon-align-right:before {
    content: "\e823";
}

.icon-arrow-down-left:before {
    content: "\e824";
}

.icon-arrow-down-right:before {
    content: "\e825";
}

.icon-anchor:before {
    content: "\e826";
}

.icon-aperture:before {
    content: "\e827";
}

.icon-arrow-left:before {
    content: "\e828";
}

.icon-arrow-right:before {
    content: "\e829";
}

.icon-arrow-down:before {
    content: "\e82a";
}

.icon-arrow-up-left:before {
    content: "\e82b";
}

.icon-arrow-up-right:before {
    content: "\e82c";
}

.icon-arrow-up:before {
    content: "\e82d";
}

.icon-award:before {
    content: "\e82e";
}

.icon-bar-chart:before {
    content: "\e82f";
}

.icon-at-sign:before {
    content: "\e830";
}

.icon-bar-chart-2:before {
    content: "\e831";
}

.icon-battery-charging:before {
    content: "\e832";
}

.icon-bell-off:before {
    content: "\e833";
}

.icon-battery:before {
    content: "\e834";
}

.icon-bluetooth:before {
    content: "\e835";
}

.icon-bell:before {
    content: "\e836";
}

.icon-book:before {
    content: "\e837";
}

.icon-briefcase:before {
    content: "\e838";
}

.icon-camera-off:before {
    content: "\e839";
}

.icon-calendar:before {
    content: "\e83a";
}

.icon-bookmark:before {
    content: "\e83b";
}

.icon-box:before {
    content: "\e83c";
}

.icon-camera:before {
    content: "\e83d";
}

.icon-check-circle:before {
    content: "\e83e";
}

.icon-check:before {
    content: "\e83f";
}

.icon-check-square:before {
    content: "\e840";
}

.icon-cast:before {
    content: "\e841";
}

.icon-chevron-down:before {
    content: "\e842";
}

.icon-chevron-left:before {
    content: "\e843";
}

.icon-chevron-right:before {
    content: "\e844";
}

.icon-chevron-up:before {
    content: "\e845";
}

.icon-chevrons-down:before {
    content: "\e846";
}

.icon-chevrons-right:before {
    content: "\e847";
}

.icon-chevrons-up:before {
    content: "\e848";
}

.icon-chevrons-left:before {
    content: "\e849";
}

.icon-circle:before {
    content: "\e84a";
}

.icon-clipboard:before {
    content: "\e84b";
}

.icon-chrome:before {
    content: "\e84c";
}

.icon-clock:before {
    content: "\e84d";
}

.icon-cloud-lightning:before {
    content: "\e84e";
}

.icon-cloud-drizzle:before {
    content: "\e84f";
}

.icon-cloud-rain:before {
    content: "\e850";
}

.icon-cloud-off:before {
    content: "\e851";
}

.icon-codepen:before {
    content: "\e852";
}

.icon-cloud-snow:before {
    content: "\e853";
}

.icon-compass:before {
    content: "\e854";
}

.icon-copy:before {
    content: "\e855";
}

.icon-corner-down-right:before {
    content: "\e856";
}

.icon-corner-down-left:before {
    content: "\e857";
}

.icon-corner-left-down:before {
    content: "\e858";
}

.icon-corner-left-up:before {
    content: "\e859";
}

.icon-corner-up-left:before {
    content: "\e85a";
}

.icon-corner-up-right:before {
    content: "\e85b";
}

.icon-corner-right-down:before {
    content: "\e85c";
}

.icon-corner-right-up:before {
    content: "\e85d";
}

.icon-cpu:before {
    content: "\e85e";
}

.icon-credit-card:before {
    content: "\e85f";
}

.icon-crosshair:before {
    content: "\e860";
}

.icon-disc:before {
    content: "\e861";
}

.icon-delete:before {
    content: "\e862";
}

.icon-download-cloud:before {
    content: "\e863";
}

.icon-download:before {
    content: "\e864";
}

.icon-droplet:before {
    content: "\e865";
}

.icon-edit-2:before {
    content: "\e866";
}

.icon-edit:before {
    content: "\e867";
}

.icon-edit-1:before {
    content: "\e868";
}

.icon-external-link:before {
    content: "\e869";
}

.icon-eye:before {
    content: "\e86a";
}

.icon-feather:before {
    content: "\e86b";
}

.icon-facebook:before {
    content: "\e86c";
}

.icon-file-minus:before {
    content: "\e86d";
}

.icon-eye-off:before {
    content: "\e86e";
}

.icon-fast-forward:before {
    content: "\e86f";
}

.icon-file-text:before {
    content: "\e870";
}

.icon-film:before {
    content: "\e871";
}

.icon-file:before {
    content: "\e872";
}

.icon-file-plus:before {
    content: "\e873";
}

.icon-folder:before {
    content: "\e874";
}

.icon-filter:before {
    content: "\e875";
}

.icon-flag:before {
    content: "\e876";
}

.icon-globe:before {
    content: "\e877";
}

.icon-grid:before {
    content: "\e878";
}

.icon-heart:before {
    content: "\e879";
}

.icon-home:before {
    content: "\e87a";
}

.icon-github:before {
    content: "\e87b";
}

.icon-image:before {
    content: "\e87c";
}

.icon-inbox:before {
    content: "\e87d";
}

.icon-layers:before {
    content: "\e87e";
}

.icon-info:before {
    content: "\e87f";
}

.icon-instagram:before {
    content: "\e880";
}

.icon-layout:before {
    content: "\e881";
}

.icon-link-2:before {
    content: "\e882";
}

.icon-life-buoy:before {
    content: "\e883";
}

.icon-link:before {
    content: "\e884";
}

.icon-log-in:before {
    content: "\e885";
}

.icon-list:before {
    content: "\e886";
}

.icon-lock:before {
    content: "\e887";
}

.icon-log-out:before {
    content: "\e888";
}

.icon-loader:before {
    content: "\e889";
}

.icon-mail:before {
    content: "\e88a";
}

.icon-maximize-2:before {
    content: "\e88b";
}

.icon-map:before {
    content: "\e88c";
}

.icon-map-pin:before {
    content: "\e88e";
}

.icon-menu:before {
    content: "\e88f";
}

.icon-message-circle:before {
    content: "\e890";
}

.icon-message-square:before {
    content: "\e891";
}

.icon-minimize-2:before {
    content: "\e892";
}

.icon-mic-off:before {
    content: "\e893";
}

.icon-minus-circle:before {
    content: "\e894";
}

.icon-mic:before {
    content: "\e895";
}

.icon-minus-square:before {
    content: "\e896";
}

.icon-minus:before {
    content: "\e897";
}

.icon-moon:before {
    content: "\e898";
}

.icon-monitor:before {
    content: "\e899";
}

.icon-more-vertical:before {
    content: "\e89a";
}

.icon-more-horizontal:before {
    content: "\e89b";
}

.icon-move:before {
    content: "\e89c";
}

.icon-music:before {
    content: "\e89d";
}

.icon-navigation-2:before {
    content: "\e89e";
}

.icon-navigation:before {
    content: "\e89f";
}

.icon-octagon:before {
    content: "\e8a0";
}

.icon-package:before {
    content: "\e8a1";
}

.icon-pause-circle:before {
    content: "\e8a2";
}

.icon-pause:before {
    content: "\e8a3";
}

.icon-percent:before {
    content: "\e8a4";
}

.icon-phone-call:before {
    content: "\e8a5";
}

.icon-phone-forwarded:before {
    content: "\e8a6";
}

.icon-phone-missed:before {
    content: "\e8a7";
}

.icon-phone-off:before {
    content: "\e8a8";
}

.icon-phone-incoming:before {
    content: "\e8a9";
}

.icon-phone:before {
    content: "\e8aa";
}

.icon-phone-outgoing:before {
    content: "\e8ab";
}

.icon-pie-chart:before {
    content: "\e8ac";
}

.icon-play-circle:before {
    content: "\e8ad";
}

.icon-play:before {
    content: "\e8ae";
}

.icon-plus-square:before {
    content: "\e8af";
}

.icon-plus-circle:before {
    content: "\e8b0";
}

.icon-plus:before {
    content: "\e8b1";
}

.icon-pocket:before {
    content: "\e8b2";
}

.icon-printer:before {
    content: "\e8b3";
}

.icon-power:before {
    content: "\e8b4";
}

.icon-radio:before {
    content: "\e8b5";
}

.icon-repeat:before {
    content: "\e8b6";
}

.icon-refresh-ccw:before {
    content: "\e8b7";
}

.icon-rewind:before {
    content: "\e8b8";
}

.icon-rotate-ccw:before {
    content: "\e8b9";
}

.icon-refresh-cw:before {
    content: "\e8ba";
}

.icon-rotate-cw:before {
    content: "\e8bb";
}

.icon-save:before {
    content: "\e8bc";
}

.icon-search:before {
    content: "\e8bd";
}

.icon-server:before {
    content: "\e8be";
}

.icon-scissors:before {
    content: "\e8bf";
}

.icon-share-2:before {
    content: "\e8c0";
}

.icon-share:before {
    content: "\e8c1";
}

.icon-shield:before {
    content: "\e8c2";
}

.icon-settings:before {
    content: "\e8c3";
}

.icon-skip-back:before {
    content: "\e8c4";
}

.icon-shuffle:before {
    content: "\e8c5";
}

.icon-sidebar:before {
    content: "\e8c6";
}

.icon-skip-forward:before {
    content: "\e8c7";
}

.icon-slack:before {
    content: "\e8c8";
}

.icon-slash:before {
    content: "\e8c9";
}

.icon-smartphone:before {
    content: "\e8ca";
}

.icon-square:before {
    content: "\e8cb";
}

.icon-speaker:before {
    content: "\e8cc";
}

.icon-star:before {
    content: "\e8cd";
}

.icon-stop-circle:before {
    content: "\e8ce";
}

.icon-sun:before {
    content: "\e8cf";
}

.icon-sunrise:before {
    content: "\e8d0";
}

.icon-tablet:before {
    content: "\e8d1";
}

.icon-tag:before {
    content: "\e8d2";
}

.icon-sunset:before {
    content: "\e8d3";
}

.icon-target:before {
    content: "\e8d4";
}

.icon-thermometer:before {
    content: "\e8d5";
}

.icon-thumbs-up:before {
    content: "\e8d6";
}

.icon-thumbs-down:before {
    content: "\e8d7";
}

.icon-toggle-left:before {
    content: "\e8d8";
}

.icon-toggle-right:before {
    content: "\e8d9";
}

.icon-trash-2:before {
    content: "\e8da";
}

.icon-trash:before {
    content: "\e8db";
}

.icon-trending-up:before {
    content: "\e8dc";
}

.icon-trending-down:before {
    content: "\e8dd";
}

.icon-triangle:before {
    content: "\e8de";
}

.icon-type:before {
    content: "\e8df";
}

.icon-twitter:before {
    content: "\e8e0";
}

.icon-upload:before {
    content: "\e8e1";
}

.icon-umbrella:before {
    content: "\e8e2";
}

.icon-upload-cloud:before {
    content: "\e8e3";
}

.icon-unlock:before {
    content: "\e8e4";
}

.icon-user-check:before {
    content: "\e8e5";
}

.icon-user-minus:before {
    content: "\e8e6";
}

.icon-user-plus:before {
    content: "\e8e7";
}

.icon-user-x:before {
    content: "\e8e8";
}

.icon-user:before {
    content: "\e8e9";
}

.icon-users:before {
    content: "\e8ea";
}

.icon-video-off:before {
    content: "\e8eb";
}

.icon-video:before {
    content: "\e8ec";
}

.icon-voicemail:before {
    content: "\e8ed";
}

.icon-volume-x:before {
    content: "\e8ee";
}

.icon-volume-2:before {
    content: "\e8ef";
}

.icon-volume-1:before {
    content: "\e8f0";
}

.icon-volume:before {
    content: "\e8f1";
}

.icon-watch:before {
    content: "\e8f2";
}

.icon-wifi:before {
    content: "\e8f3";
}

.icon-x-square:before {
    content: "\e8f4";
}

.icon-wind:before {
    content: "\e8f5";
}

.icon-x:before {
    content: "\e8f6";
}

.icon-x-circle:before {
    content: "\e8f7";
}

.icon-zap:before {
    content: "\e8f8";
}

.icon-zoom-in:before {
    content: "\e8f9";
}

.icon-zoom-out:before {
    content: "\e8fa";
}

.icon-command:before {
    content: "\e8fb";
}

.icon-cloud:before {
    content: "\e8fc";
}

.icon-hash:before {
    content: "\e8fd";
}

.icon-headphones:before {
    content: "\e8fe";
}

.icon-underline:before {
    content: "\e8ff";
}

.icon-italic:before {
    content: "\e900";
}

.icon-bold:before {
    content: "\e901";
}

.icon-crop:before {
    content: "\e902";
}

.icon-help-circle:before {
    content: "\e903";
}

.icon-paperclip:before {
    content: "\e904";
}

.icon-shopping-cart:before {
    content: "\e905";
}

.icon-tv:before {
    content: "\e906";
}

.icon-wifi-off:before {
    content: "\e907";
}

.icon-minimize:before {
    content: "\e88d";
}

.icon-maximize:before {
    content: "\e908";
}

.icon-gitlab:before {
    content: "\e909";
}

.icon-sliders:before {
    content: "\e90a";
}

.icon-star-on:before {
    content: "\e90b";
}

.icon-heart-on:before {
    content: "\e90c";
}