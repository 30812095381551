@import "src/assets/themes/_variable.scss";
@import "~handsontable/dist/handsontable.full.css";

body {
  background-color: #f3f3f3;
  font-size: 0.875em;
  overflow-x: hidden;
  color: #353c4e;
  font-family: Roboto, sans-serif;
  background-attachment: fixed;
}
.font_13 {
  font-size: 13px;
}
.font_12 {
  font-size: 12px !important;
}
.font_11 {
  font-size: 11px !important;
}
.font_14 {
  font-size: 14px !important;
}
.font_15 {
  font-size: 15px !important;
}
.font_16 {
  font-size: 16px !important;
}
.font_10 {
  font-size: 10px !important;
}
.font_35px {
  font-size: 30px !important;
}
.mat-datetimepicker-calendar-header {
  background-color: #00bdb3 !important;
}
.font_13 {
  font-size: 13px;
}
.font_12 {
  font-size: 12px;
}
.font_11 {
  font-size: 11px;
}
.font_14 {
  font-size: 14px;
}
.font_15 {
  font-size: 15px;
}
.font_16 {
  font-size: 16px;
}
.font_10 {
  font-size: 10px;
}
.skeleton_loader {
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  min-height: 1.6rem;
  border-radius: 4px;
  animation-delay: 0.05s;
  margin-bottom: 0.25rem;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
.shortname_badge {
  background-color: #f1f5fa;
  padding: 5px;
  border-radius: 50%;
  font-size: 12px;
  margin-right: 4px;
}
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-30 {
  width: 30% !important;
}
.br-l {
  border-left: 1px solid #ddd;
}
.br-b {
  border-bottom: 1px solid #ddd;
}
.br-r {
  border-right: 1px solid #ddd;
}
.br-t {
  border-top: 1px solid #ddd;
}
.overflow_ellipsis {
  display: block;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.two_line_overflow_ellipsis {  
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.inputDnD {
  .form-control-file {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 6em;
    outline: none;
    visibility: hidden;
    cursor: pointer;
    box-shadow: 0 0 5px solid #00bdb3;
    &:before {
      content: attr(data-title);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 6em;
      line-height: 2em;
      padding-top: 1.5em;
      opacity: 1;
      visibility: visible;
      text-align: center;
      border: 0.25em dashed #00bdb3;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      overflow: hidden;
      border-radius: 6px;
      background-color: #00bdb31c;
    }
  }
}
.clr_cyan {
  color: #00bdb3;
}
.clr_red {
  color: #ff4d5f;
}
.wht_space_brk {
  white-space: break-spaces;
}
.orange {
  color: orange !important;
}
.lyt_txt {
  color: #0000008c;
}
.red {
  color: #ff4d5f;
}
.green {
  color: #36cc9b;
}
.cyan {
  color: #00bdb3;
}
.text-underline {
  text-decoration: underline;
}
.light {
  color: #b6acac;
}

// nav bar profile{
.user_profile::after {
  display: none;
}

.clr-fff {
  color: #fff;
}

.warning-msg {
  color: #9F6000;
  background-color: #FEEFB3;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.profile-notification {
  background: #fff;
  -webkit-box-shadow: 0 2px 30px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 30px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 24rem;
  right: 6px;
  top: 53px;
  left: auto;
  overflow: visible;
  border: none;
}

.profile-notification:after {
  content: "";
  position: absolute;
  top: -10px;
  right: 30px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 10px solid #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-box-shadow: -10px -9px 23px 1px rgba(0, 0, 0, 0.1);
  box-shadow: -10px -9px 23px 1px rgba(0, 0, 0, 0.1);
}

.profile-notification a {
  color: #666;
}

.profile-notification li {
  border-top: 1px solid #01a9ac, #fe9365, #e0e0e0, #fe5d70, #0ac282, #404e67,
    #2dcee3;
  line-height: initial;
  padding: 1em 20px;
  width: 100%;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.profile-notification li:first-child {
  padding: 10px 20px;
}

.profile-notification li:first-child h6 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.profile-notification li:first-child label {
  float: right;
}

.profile-notification li img {
  width: 40px;
  margin-right: 10px;
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.profile-notification li:hover {
  background-color: #f1f1f1;
}

.profile-notification li:first-child:hover {
  background-color: transparent;
}

.profile-notification .notification-user {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.profile-notification .notification-msg {
  margin-bottom: 5px;
}

.profile-notification .notification-time {
  font-size: 12px;
  color: #919aa3;
}

.profile-notification {
  width: 15em;
}

.profile-notification a {
  font-size: 15px;
  vertical-align: top;
  padding-left: 0;
}

.profile-notification a:hover {
  background-color: transparent;
}

.profile-notification i {
  font-size: 17px;
  margin-left: 4px;
  margin-right: 15px;
}

.profile-notification li {
  border-top: none;
  padding: 0.7em 20px;
}

.profile-notification li:first-child {
  padding: 0.7em 20px;
}

.f-8 {
  font-size: 18px;
  cursor: pointer;
}

a:hover,
a:focus,
a:active {
  text-decoration: unset !important;
}

.mat-drawer-content {
  background-color: #f1f5fa;
}

.logo-text:after {
  content: "";
  background-color: #00bdb3;
  position: absolute;
  left: 0px;
  top: 15px;
  width: 4px;
  height: 30px;
}

.plus-button {
  border-radius: 100% !important;
  background-color: #ff4d5f !important;
  position: relative;
  top: -30px;
  right: 40px;
  width: 50px !important;
  height: 50px !important;
  min-width: 50px !important;
  padding: 0 10px !important;
}

.card-shadow {
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
}

.mat-checkbox .mat-checkbox-layout {
  margin-bottom: 3px !important;
}

.container-fluid {
  .row {
    margin: 0 25px 0 0;
  }

  .content-card {
    margin-top: 26px;
  }

  .header {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center space-between;
    align-items: center;

    .logo {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      position: relative;
      place-content: center flex-start;
      align-items: center;

      .logo-text {
        font-size: 22px;
      }

      .logo-text:after {
        content: "";
        background-color: $bgcolor;
        position: absolute;
        left: -25px;
        top: 0;
        width: 4px;
        height: 30px;
      }
    }

    .search-wrapper {
      background-color: #f5f7fa;
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      border-radius: 50px;
      width: 315px;

      mat-form-field {
        width: 100%;
        padding: 0 15px;
      }
    }
  }
}

.search-wrapper .mat-form-field-wrapper {
  padding-bottom: 0 !important;
  text-align: left;
}

.search-wrapper .mat-form-field-infix {
  padding: 1em 0;
  border-top: unset !important;
}

.search-wrapper .mat-form-field-prefix {
  top: 4px;
  left: -9px;
}

// custom background
.bg-dadada {
  background-color: #dadada;
}

.bg-ff4d5f {
  background-color: #ff4d5f;
}

.bg-fff {
  background-color: #fff !important;
}

// custom Margins
.mr-60 {
  margin-right: 60px !important;
}

.m-0-10 {
  margin: 0 10px;
}

.cursor-pointer {
  cursor: pointer;
}

// custom bottom
.b-5 {
  bottom: 5px;
}

// custom padding
.p-10 {
  padding: 10px;
}

.p-125-rem {
  padding: 1.25rem;
}

.p-t-125-rem {
  padding-top: 1.25rem;
}

// custom width{
.w-15 {
  width: 15%;
}

.w-35 {
  width: 35% !important;
}

.w-85 {
  width: 85% !important;
}

.w-95 {
  width: 95% !important;
}

.w-400 {
  width: 400px;
}

// custom height

.h-90 {
  height: 90%;
}

.h-in {
  height: inherit;
}

.h-400 {
  height: 400px;
}

//overflow
.overflow-h {
  overflow: hidden;
}

.overflow-hauto {
  overflow: auto;
}

.h-inherit {
  height: inherit;
}

.mat-checkbox-background {
  border: 2px solid #00bdb3;
}

.mat-checkbox-mixedmark {
  background-color: #fff;
}

.mat-checkbox-checkmark-path {
  stroke: #fff !important;
}

.mat-raised-button[disabled] {
  background-color: #ff4d5f82 !important;
  color: #fff !important;
}

.mat-header-row,
.mat-paginator {
  background-color: #fafafa !important;
}

.suffix-search {
  top: -3px;
  position: relative;
  font-size: 18px;
  color: #00bdb3;
}

//   for mobile
@media only screen and (max-width: 600px) {
  .tab-content {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    max-width: 100%;
    height: 100vh !important;
    overflow: scroll !important;
  }
  .mat-dialog-container {
    min-width: 320px !important;
  }
  .m-w-15 {
    width: 15% !important;
  }

  .m-w-75 {
    width: 75% !important;
  }

  .m-w-100 {
    width: 100% !important;
  }

  .m-h-100 {
    height: 100px;
  }

  .container-fluid {
    height: 100%;
    padding: 0;
    overflow-y: scroll;
  }

  .sidebar {
    margin-top: 57px !important;
  }

  .m-menu-tog {
    width: 45px;
  }

  .m-center-tog {
    width: calc(100% - 100px);
  }

  .m-last-tog {
    width: 45px;
  }

  .mat-toolbar.mat-primary {
    position: fixed;
    z-index: 9999999;
    box-shadow: 1px 0 10px 0 #3f4d6769;
  }

  .mat-tab-body-wrapper {
    height: inherit !important;
    overflow: auto;
  }

  .container-fluid {
    .mat-card {
      height: 100%;
      margin: 3em 0 !important;
      margin-top: 0 !important;

      .mat-card-content {
        height: 100%;
      }
    }
  }

  .plus-button {
    border-radius: 100% !important;
    background-color: #ff4d5f !important;
    position: relative;
    top: -85px;
    right: -85%;
    height: 40px;
    width: 40px;
    min-width: 35px !important;
    padding: 0 8px !important;
  }

  .card-content {
    height: 480px;
    overflow: auto;
  }

  .search-wrapper {
    margin-top: 12px;
    margin-bottom: -25px;
  }

  .navbar {
    background-color: #fff !important;
  }

  .mobile-caret-hide::after {
    display: none !important;
  }

  .main_logo {
    width: 70%;
    margin: 0 40px;
  }
}

// Preview Image
.pic-50 {
  width: 50px;
  height: 50px;
}

//center table header
.table-header .mat-sort-header-container {
  justify-content: center;
}

.box {
  display: inline-block;
  width: 200px;
  height: 200px;
  margin: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}

.upload-options {
  position: relative;
  background-color: cadetblue;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  transition: background-color ease-in-out 150ms;
  /* bottom       : -226px; */
  width: 100%;
  height: 100%;
}

.upload-options:hover {
  background-color: #7fb1b3;
}

.upload-options input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-options label {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
}

.upload-options label::after {
  content: "add";
  font-family: "Material Icons";
  position: absolute;
  font-size: 2.5rem;
  color: #e6e6e6;
  top: calc(50% - 2.5rem);
  left: calc(50% - 1.25rem);
  z-index: 0;
}

.upload-options label span {
  display: inline-block;
  width: 50%;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
}

.upload-options label span:hover i.material-icons {
  color: lightgray;
}

//hover effect
.bg-hover:hover {
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

//active bg
.activeMenu {
  background: rgba(0, 0, 0, 0.04);
  font-weight: bold;
}

// media Queries
@media only screen and (min-width: 992px) and (max-width: 1380px) {
  .mat-dialog-container {
    min-width: 400px !important;
  }
  .main-cont-side {
    margin-top: 64px;
  }

  .w-h-vh {
    height: 86vh;
  }

  .card-content {
    height: 445px;
    overflow: auto;
  }

  .w-w-15 {
    width: 15% !important;
  }

  .w-w-75 {
    width: 75% !important;
  }

  .container-fluid {
    // margin: 20px;
    padding: 0;
    overflow: scroll;
    height: 100%;

    // width: 97%;
    .mat-card {
      margin-top: 0 !important;
    }
  }
}

@media only screen and (min-width: 1380px) {
  .mat-dialog-container {
    min-width: 400px !important;
  }
  .main-cont-side {
    margin-top: 64px;
  }

  .w-h-vh {
    height: 86vh;
  }

  .card-content {
    height: 470px;
    overflow: auto;
  }

  .w-w-15 {
    width: 15% !important;
  }

  .w-w-75 {
    width: 75% !important;
  }

  .container-fluid {
    padding: 0;
    overflow-y: scroll;
    height: 100%;

    .mat-card {
      margin-top: 0 !important;
    }
  }
}

// Indicators
.quantity-indicator {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quantity-indicator.red {
  background-color: #f44336;
  color: #f44336;
}

.quantity-indicator.orange {
  background-color: orange !important;
  color: orange !important;
}

.quantity-indicator.green {
  background-color: #43a047 !important;
  color: #43a047 !important;
}

td mat-icon {
  cursor: pointer;
  color: #000;
}

.mat-dialog-container {
  .mat-form-field {
    width: 100% !important;
  }

  .manage-dialog {
    .mat-card-title {
      margin-bottom: 0;
    }

    .mat-card-header {
      margin-bottom: 20px;
    }

    .mat-card-content {
      .mat-form-field-wrapper {
        padding-bottom: 0;
        margin-bottom: 20px;
      }
    }

    .mat-card-actions {
      padding: 0;
      margin-bottom: 0;
    }
  }
}
#o-lay-loader {
  padding: 0;
  margin: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  background: #000000c9;
  .wrapper {
    width: 200px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #00bdb3;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.wrapper span {
  position: absolute;
  top: 75px;
  font-family: inherit;
  font-size: 20px;
  letter-spacing: 2px;
  color: #00bdb3;
  left: 2%;
  width: 100%;
}
.multi-select__choices {
  display: flex;
  flex-direction: column;
  min-width: 10rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  font-size: 16px;
  &:focus {
    outline: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
  [hidden] {
    display: none !important;
  }
  &.is-open {
    overflow: initial;
  }
  &.is-disabled {
    .multi-select__choices__inner {
      background-color: #eaeaea;
      cursor: not-allowed;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .multi-select__choices__input {
      background-color: #eaeaea;
      cursor: not-allowed;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .multi-select__choices__item {
      cursor: not-allowed;
    }
  }
}
.multi-select__choices[data-type*="select-multiple"] {
  .multi-select__choices__inner {
    cursor: text;
  }
  .multi-select__choices__button {
    position: relative;
    display: inline-block;
    margin-top: 0;
    margin-right: -2px;
    margin-bottom: 0;
    margin-left: 0px;
    padding-left: 13px;
    border-left: 1px solid #00bdb3;
    background-image: url("../svgs/cross.svg");
    background-size: 8px;
    width: 8px;
    opacity: 0.75;
    border-radius: 0;
    &:hover {
      opacity: 1;
    }
    &:focus {
      opacity: 1;
    }
  }
}
.multi-select__choices[data-type*="text"] {
  .multi-select__choices__inner {
    cursor: text;
  }
  .multi-select__choices__button {
    position: relative;
    display: inline-block;
    margin-top: 0;
    margin-right: -2px;
    margin-bottom: 0;
    margin-left: 0px;
    padding-left: 13px;
    border-left: 1px solid #00bdb3;
    background-image: url("../svgs/cross.svg");
    background-size: 8px;
    width: 8px;
    opacity: 0.75;
    border-radius: 0;
    &:hover {
      opacity: 1;
    }
    &:focus {
      opacity: 1;
    }
  }
}
.multi-select__choices__inner {
  vertical-align: top;
  background-color: #ffffff;
  padding: 3px 3px 0;
  border: 2px solid #00bdb3;
  font-size: 14px;
  overflow: hidden;
}
.is-focused {
  .multi-select__choices__inner {
    border-color: #00bdb3;
  }
}
.is-open {
  .multi-select__choices__inner {
    border-color: #00bdb3;
  }
  .multi-select__choices__list--dropdown {
    border-color: #b7b7b7;
  }
}
.multi-select__choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.multi-select__choices__list--single {
  .multi-select__choices__item {
    width: 100%;
  }
}
.multi-select__choices__list--multiple {
  display: inline;
  .multi-select__choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 20px;
    padding: 2px 7px;
    font-size: 12px;
    font-weight: inherit;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #00bdb3;
    border: 1px solid #00bdb3;
    color: #ffffff;
    word-break: break-all;
    box-sizing: border-box;
    &.is-highlighted {
      background-color: #00bdb3;
      border: 1px solid #00bdb3;
    }
  }
  .multi-select__choices__item[data-deletable] {
    padding-right: 5px;
  }
}
[dir="rtl"] {
  .multi-select__choices__list--multiple {
    .multi-select__choices__item {
      margin-right: 0;
      margin-left: 3.75px;
    }
  }
  .multi-select__choices__list--dropdown {
    .multi-select__choices__item {
      text-align: right;
    }
  }
  .multi-select__choices__input {
    padding-right: 2px;
    padding-left: 0;
  }
}
.is-disabled {
  .multi-select__choices__list--multiple {
    .multi-select__choices__item {
      background-color: #eaeaea;
      border: 1px solid #eaeaea;
    }
  }
}
.multi-select__choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  background-color: #ffffff;
  border: 1px solid #b7b7b7;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
  .multi-select__choices__list {
    position: relative;
    max-height: 200px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
  }
  .multi-select__choices__item {
    position: relative;
    padding: 5px;
    padding-right: 2rem;
    font-size: 12px;
  }
  .multi-select__choices__item--selectable {
    &.is-highlighted {
      background-color: #00bdb3;
      color: #ffffff;
      &:after {
        opacity: 0.5;
      }
    }
  }
  &.is-active {
    visibility: visible;
  }
}
.is-flipped {
  .multi-select__choices__list--dropdown {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
  }
}
.multi-select__choices__item {
  cursor: default;
}
.multi-select__choices__item--selectable {
  cursor: pointer;
}
.multi-select__choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.5;
}
.multi-select__choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.multi-select__choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #ffffff;
  font-size: 12px;
  border: 0;
  border-radius: 0;
  padding: 3px 0 3px 2px;
  &:focus {
    outline: 0;
  }
}
.multi-select__choices__placeholder {
  opacity: 0.5;
}
.content {
  width: 100%;
  margin: 10rem 0;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  color: #363537;
  h1 {
    color: #363537;
    font-family: "Montserrat", "sans-serif";
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
  }
  code {
    display: inline-block;
    color: #ef2d56;
    border: 1px solid #eeeeee;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
  }
  #sheet {
    margin: 2rem auto 0 auto;
    text-align: initial;
  }
}
.content h1,
.content p {
  margin: 0;
}
.multi-select__multi-select__choices {
  background-color: #ffffff;
}

.dialog-responsive {
  height: 90%;
  max-height: 80%;
}

@media only screen and (max-width: 760px) {
  .dialog-responsive {
      height: 90%;
      max-height: 80%;
  }
}